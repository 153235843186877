
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import RankingHistoryService from '@/modules/common/modules/ranking-history/ranking-history.service';
import type Day from '@/modules/common/types/day.type';
// @ts-ignore
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import type UserService from '@/modules/user/user.service';
import type ProvidersService from '@/modules/providers/providers.service';
import RankingHistoryHeader from './ranking-history-header.vue';
import RankingHistoryTable from './ranking-history-table.vue';
import RankingHistoryChart from './ranking-history-chart.vue';

enum GRAPH_STATE {
    RANK = 'titles.rank',
    REVIEW = 'titles.reviews',
}

@Component({
    components: {
        ModalWrapper,
        RankingHistoryHeader,
        RankingHistoryChart,
        ProviderCard,
        RankingHistoryTable,
    },
})
export default class RankingHistoryPopup extends Vue {
    @inject(KEY.RankingHistoryService) private rankingHistoryService!: RankingHistoryService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    graphState: GRAPH_STATE = GRAPH_STATE.RANK;
    hiddenGraphs: Record<string, boolean> = {};

    mounted(): void {
        if (!this.userService.currentHotelId) {
            this.$router.back();
        }
    }

    get skeleton() {
        return this.rankingHistoryService.isLoading;
    }

    get historyData() {
        return this.rankingHistoryService.rankingHistory(this.provider);
    }

    get tableData() {
        if (!this.historyData) {
            return null;
        }

        const index = this.tableDay === null ? this.historyData.length - 1 : this.tableDay;

        return this.historyData[index];
    }

    get tableDay(): Day | null {
        return this.rankingHistoryService.tableDay;
    }

    get provider() {
        return this.$route.params.provider;
    }

    get isReverted() {
        return this.graphState === GRAPH_STATE.REVIEW;
    }

    setTableDay(day: Day | null) {
        this.rankingHistoryService.tableDay = day;
    }

    resetPopupData() {
        this.setTableDay(null);
        this.rankingHistoryService.resetLoading();
    }

    toggleGraph() {
        this.graphState = this.graphState === GRAPH_STATE.RANK
            ? GRAPH_STATE.REVIEW
            : GRAPH_STATE.RANK;
    }

    toggleHotelGraph(hotelId: number) {
        this.hiddenGraphs = {
            ...this.hiddenGraphs,
            [hotelId]: !this.hiddenGraphs[hotelId],
        };
    }

    getMaxRank(provider: string) {
        if (!this.providersService.allProvidersCollection) {
            return 10;
        }

        const currentProvider = this.providersService.allProvidersCollection.find(p => p.name === provider);

        if (!currentProvider) {
            return 10;
        }

        return currentProvider.maxScore;
    }
}
